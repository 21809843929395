import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ProfileComponent from "../../components/Global/Settings/Profile";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

function Profile() {
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Profile
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <NavLink to="/spaces/space-apps">Dashboard</NavLink>
            <NavLink to="/spaces/space-apps">Settings</NavLink>
            <Typography>Profile</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={NavLink}
              to="/dashboard"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ProfileComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Profile;
