import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

function Profile() {
  const { user, isAuthenticated, isInitialized } = useAuth();
  const [loading, setLoading] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [base64Image, setBase64Image] = useState(
    "/static/img/avatars/avatar-1.jpg"
  );

  // Fetch user data when component mounts
  useEffect(() => {
    console.log(
      "!!!!!!!!!!!! logged in user",
      user,
      isAuthenticated,
      isInitialized
    );
    const fetchProfile = async () => {
      try {
        const userData = user;
        formik.setValues({
          name: userData.name,
          email: userData.email,
        });
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "", // Preloaded from the backend
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
          mutation updateProfile($name: String!, $avatar: String) {
            updateProfile(name: $name, avatar: $avatar) {
              success
              message
            }
          }`,
          variables: {
            name: values.name,
            avatar: base64Image,
          },
        });

        if (response.data.updateProfile.success) {
          setSnackBarMsg("Profile updated successfully");
        } else {
          setSnackBarMsg(response.data.data.updateProfile.message);
        }
      } catch (error) {
        setSnackBarMsg("An error occurred while updating profile");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    setSnackBarMsg("");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={6}>
            <Grid item md={8}>
              <TextField
                type="text"
                label="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                my={2}
              />
              <TextField
                type="email"
                label="Email ID"
                name="email"
                value={formik.values.email} // Preloaded email from backend
                disabled // Disable email update
                fullWidth
                my={2}
              />
            </Grid>
            <Grid item md={4}>
              <CenteredContent>
                <div
                  style={{
                    width: "120px",
                    height: "120px",
                    margin: "0px auto 8px",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    src={base64Image}
                    alt="Uploaded"
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: "0 auto",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <CloudUpload mr={2} /> Upload
                  </Button>

                  <Typography variant="caption" display="block" gutterBottom>
                    For best results, use an image at least 128px by 128px in
                    .jpg format
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save changes
            {loading && (
              <CircularProgress
                sx={{ color: "#fff" }}
                size={15}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </form>
        <Snackbar
          open={Boolean(snackBarMsg)}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackBarMsg}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
}

export default Profile;
