import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import SettingsLayout from "./layouts/ProfileSettings";
import ApplicationsLayout from "./layouts/Applications";
import PresentationLayout from "./layouts/Presentation";
import CalendarBookLayout from "./layouts/CalendarBook";

// Sidebar items

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
import Blank from "./pages/pages/Blank";
import Pricing from "./pages/pages/Pricing";
import Chat from "./pages/pages/Chat";

// Settings
// Pages Roles
import Roles from "./pages/pages/Roles";
import RolesAdd from "./pages/pages/RolesAdd";
import Users from "./pages/pages/Users";
import Groups from "./pages/pages/Groups";
import Integrations from "./pages/settings/Integrations";
import BusinessSettings from "./pages/pages/BusinessSettings";
import Profile from "./pages/pages/Profile";
import ChangePassword from "./pages/pages/ChangePassword";
// Teams
import Teams from "./pages/calendar/Teams/Teams";
// Event Types
import EventTypes from "./pages/calendar/EventTypes/EventTypes";
import EventTypeAdd from "./pages/calendar/EventTypes/EventTypeAdd";

// Events
import Events from "./pages/Events/Events";
import EventAdd from "./pages/Events/EventAdd";
import EventView from "./pages/Events/EventView";

// Bookings
import Bookings from "./pages/calendar/Teams/Bookings";

// Public Calendar
import Preview from "./pages/calendar/Public/Preview";

// Availability
import MyAvailabilityAdd from "./pages/calendar/Availibility/MyAvailabilityAdd";

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

// Icon components
// const Profile = async(() => import("./pages/pages/Profile"));

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <SettingsLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "business-settings",
        element: <BusinessSettings />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "roles-permissions",
        element: <Roles />,
      },
      {
        path: "roles-permissions-add",
        element: <RolesAdd />,
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "integrations",
        element: <Integrations />,
      },
    ],
  },
  {
    path: "calendar",
    element: (
      <AuthGuard>
        <ApplicationsLayout sidebarItems="calendar" />
      </AuthGuard>
    ),
    children: [
      {
        path: "teams",
        element: <Teams />,
      },
      {
        path: "event-types",
        element: <EventTypes />,
      },
      {
        path: "add-event-types",
        element: <EventTypeAdd />,
      },
      {
        path: "availability",
        element: <MyAvailabilityAdd />,
      },
      {
        path: "bookings",
        element: <Bookings />,
      },
    ],
  },
  {
    path: "events",
    element: (
      <AuthGuard>
        <ApplicationsLayout sidebarItems="events" />
      </AuthGuard>
    ),
    children: [
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "add-event",
        element: <EventAdd />,
      },
      {
        path: "view-event",
        element: <EventView />,
      },
    ],
  },
  {
    path: "/preview",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <Preview />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
